var x, i, j, selElmnt, a, b, c;
x = document.getElementsByClassName("custom-select");
for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < selElmnt.length; j++) {
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function(e) {
        var y, i, k, s, h;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        for (i = 0; i < s.length; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            for (k = 0; k < y.length; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
  });
}
function closeAllSelect(elmnt) {
  var x, y, i, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
document.addEventListener("click", closeAllSelect);

jQuery(function($){
    "use strict";
    var width_screen = $( document ).width();
    
    if(width_screen > 1200){
        $('#header-menu>ul>li.btn-open-menu-servicos>a').on('click', function(e){
            e.preventDefault();
            $('#header-submenu').slideToggle();
        });
    }else{
        $('#header-menu>ul>li.menu-item-has-children>a').on('click', function(e){
            e.preventDefault();
            $(this).parent().children('.sub-menu').slideToggle();
        });
    }

    $('#open-menu-mobile').on('click', function(e){
        e.preventDefault();
        $('#header-menu').animate({'right': 0}, 800);
    });
    $('#close-menu-mobile').on('click', function(e){
        e.preventDefault();
        $('#header-menu').animate({'right': -350}, 800);
    });

    $('#header-submenu .btn-categoria').on('click', function(){

        var item = $( this );
        var subitem = $( '#header-submenu #' + item.attr('data-submenu-id') );

        $('#header-submenu .btn-categoria').removeClass('active');
        $('#header-submenu .btn-categoria .fa').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        $('#header-submenu .menu-servicos').hide().removeClass('active');
        
        item.addClass('active');
        item.find('.fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        subitem.slideDown().addClass('active');
    });

    
    $(".tel").mask("(00) 90000-0000");

	//FILE CONTACT
	$(".file-button").change(function(){
		$(this).closest("span").next().after().text($(this).val().split('\\').slice(-1)[0])
	});

	$("header .open-menu").click(function(){
		$(".mobile-menu").addClass("active");
		$("body").addClass("overflow");
		return false;
	});

	$(".close-menu").click(function(){
		$(".mobile-menu").removeClass("active");
		$("body").removeClass("overflow");
		return false;
	});

	$(".menu-bar .open-menu-bar").click(function(){
		$("#menu-menu_blog").slideToggle();
		return false;
	});

	$(".btn-scroll").click(function(){
		var aTag = $(this).attr("href");
    	$('html,body').animate({scrollTop: $(aTag).offset().top},'slow');
		return false;
	});

	//ARROW MENU
	$(".menu-bar nav li.menu-item-has-children").each(function(){
		$(this).append("<span><i class='fa fa-angle-down'></span>");
	});

	//CAROUSEL BANNER
	$('.banner').owlCarousel({
		autoplay: true,
        autoPlaySpeed: 5000,
        autoPlayTimeout: 5000,
		loop: true,
		responsive:{
	        0:{
	            items:1,
	        },
	        680:{
	            items:1,
	        },
	        1000:{
	            items:1,
	        }
	    },
		dots: false,
		navText: ["", ""]
	});

	//CAROUSEL ABOUT
	$('.carousel-about').owlCarousel({
		loop: true,
		responsive:{
	        0:{
	            items:1,
	        },
	        680:{
	            items:1,
	        },
	        1000:{
	            items:1,
	        }
	    },
		dots: true,
		nav: false
	});

	//CAROUSEL CASES
	$('.carousel-cases').owlCarousel({
		loop: true,
		margin: 30,
		responsive:{
	        0:{
	            items:1,
	        },
	        680:{
	            items:2,
	        },
	        1000:{
	            items:3,
	        }
	    },
		dots: false,
		nav: true,
		navText: ["<span class='sr-only'>anterior</span><i class='fa fa-arrow-left'></i>", "<span class='sr-only'>proximo</span><i class='fa fa-arrow-right'></i>"]
	});

	//CAROUSEL DEPOIMENTOS
	$('.carousel-testimonials').owlCarousel({
		loop: true,
		autoplay: false,
		margin: 30,
		responsive:{
	        0:{
	            items:1,
	        },
	        680:{
	            items:1,
	        },
	        1000:{
	            items:1,
	        }
	    },
		dots: false,
		nav: true,
		navText: ["<span class='sr-only'>anterior</span><i class='fa fa-arrow-left'></i>", "<span class='sr-only'>proximo</span><i class='fa fa-arrow-right'></i>"]
	});

    //CAROUSEL PARCEIROS
    $('.carousel-partners').owlCarousel({
		loop: true,
		autoplay: true,
        autoPlaySpeed: 2000,
        autoPlayTimeout: 1000,
		margin: 60,
		responsive:{
            0:{
                items:1,
            },
	        680:{
	            items:2,
	        },
	        768:{
	            items:3,
	        },
	        1100:{
	            items:4,
	        }
	    },
		dots: false,
		nav: false,
		navText: ["<span class='sr-only'>anterior</span><i class='fa fa-arrow-left'></i>", "<span class='sr-only'>proximo</span><i class='fa fa-arrow-right'></i>"]
	});
});